import React, { FormEvent, useState } from 'react';
import Select from 'react-select';
import './contact-us.scss';

interface ContactUsProps { }

interface ContactUsState {
   services: ServiceItem[],
   selected: '',
   emailSent: boolean
}

interface ServiceItem {
   label: string,
   value: string
}

class ContactUs extends React.Component<ContactUsProps, ContactUsState> {

   constructor(props: ContactUsProps) {
      super(props);
      this.state = {
         services: [
            {
               label: 'Guardias de Seguridad residenciales y para condominios.',
               value: 'GSRC'
            },
            {
               label: 'Guardias de Seguridad Centro Comerciales.',
               value: 'GSCC'
            },
            {
               label: 'Guardias de Seguridad para construcciones y obras.',
               value: 'GSCO'
            },
            {
               label: 'Guardias de Seguridad empresariales e industriales.',
               value: 'GSEI'
            },
            {
               label: 'Guardias de seguridad para eventos.',
               value: 'GSE'
            },
            {
               label: 'Guardias de Seguridad Corporativos.',
               value: 'GSC'
            }
         ],
         emailSent: false,
         selected: ''
      }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleOnChange = this.handleOnChange.bind(this);
      this.renderForm = this.renderForm.bind(this);
      this.renderAfterSubmition = this.renderAfterSubmition.bind(this);
   }

   handleSubmit(e: FormEvent) {
      e.preventDefault();
      const formData = new FormData();
      const nameInput: HTMLInputElement = document.querySelector('input[name=name]') as HTMLInputElement;
      const lastnameInput: HTMLInputElement = document.querySelector('input[name=lastname]') as HTMLInputElement;
      const phoneInput: HTMLInputElement = document.querySelector('input[name=phone]') as HTMLInputElement;
      const emailInput: HTMLInputElement = document.querySelector('input[name=email]') as HTMLInputElement;

      formData.append('name', nameInput.value);
      formData.append('lastname', lastnameInput.value);
      formData.append('phone', phoneInput.value);
      formData.append('email', emailInput.value);
      formData.append('service', this.state.selected);

      

      fetch('/lead/send', {
         method: 'post',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify(Object.fromEntries(formData))
      })
         .then((res) => res.json())
         .then((result) => {
            this.setState({ emailSent: true });
         })
         .catch((e) => {
            console.error(e);
         });
   }

   handleOnChange(data: any) {
      this.setState({ selected: data.label });
   }

   renderForm() {
      return (
         <form onSubmit={this.handleSubmit}>
            <fieldset>
               <div className="field is-grouped">
                  <div className="control is-expanded">
                     <input type="text" className="input" name='name' placeholder='Nombre' required/>
                  </div>
                  <div className="control is-expanded">
                     <input type="text" className="input" name='lastname' placeholder='Apellido' required/>
                  </div>
               </div>

               <div className="field">
                  <div className="control">
                     <input type="text" className="input" name='phone' placeholder='Tel&eacute;fono de contacto' required/>
                  </div>
               </div>

               <div className="field">
                  <div className="control">
                     <input type="text" className="input" name='email' placeholder='Correo Electr&oacute;nico' required/>
                  </div>
               </div>

               <div className="field">
                  <div className="control">
                     <div className="select is-fullwidth">
                        <Select onChange={this.handleOnChange} placeholder={"En que servicio se encuentra interesado..."} options={this.state.services} name="services"  required/>
                     </div>
                  </div>
               </div>

               <div className="field">
                  <div className="control">
                     <button className="button is-fullwidth submit-btn">Solicitar Cotizaci&oacute;n</button>
                  </div>
               </div>
               <div className="field">
                  <div className="control">
                     <p className="has-text-centered">&oacute; tambi&eacute;n</p>
                  </div>
               </div>
               <div className="field">
                  <div className="control">
                     <a href='tel:2224051520' className="button is-primary is-fullwidth">Ll&aacute;manos ahora:  +52 222 405 1520</a>
                  </div>
               </div>
            </fieldset>
         </form>
      );
   }

   renderAfterSubmition() {
      return (
         <div className="notification is-success is-light has-text-centered" >
            <h1 className="subtitle is-2">Su solicitud de cotizaci&oacute;n ha sido enviada.</h1>
            <a href="/contact" className='button is-primary is-fullwidth'>Solicitar otra cotizaci&oacute;n</a>
         </div >
      );
   }

   render() {
      const isEmailSent = this.state.emailSent;

      return (
         <React.Fragment>
            <section className="hero contact-us">
               <div className="hero-body">
                  <h1 className="hero-title">Cont&aacute;ctanos.</h1>
               </div>
            </section>
            <section className="contact-us">
               <div className="container">
                  <div className="columns is-vcentered">
                     <div className="column is-5 is-formtext">
                        <h1 className="title">Queremos ayudarle <br />a mejorar su seguridad</h1>
                        <p className="text">¡Gracias por visitar nuestro sitio Web! Siempre estamos felices de escuchar a nuestros clientes y apreciamos sus comentarios. Si tiene alguna pregunta, comentario o inquietud, no dude en comunicarse con nosotros. </p>
                        <p className="text">Nuestro equipo está disponible para responder sus consultas de lunes a viernes de 9 am a 5 pm CST.</p>
                     </div>
                     <div className="column is-7 is-formbox">
                        {isEmailSent ? this.renderAfterSubmition() : this.renderForm()}
                     </div>
                  </div>
               </div>
            </section>
         </React.Fragment>
      );
   }
}

export default ContactUs;