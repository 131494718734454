import React, { useEffect } from 'react';
import Footer from '../../core/footer/Footer';
import Hero from '../../core/hero/Hero';
import Identity from '../../core/identity/Identity';
import { Outlet } from 'react-router-dom';
import './root.screen.scss';

class RootScreen extends React.Component {

   componentDidMount() {
      document.title = "SISEGUARD | Seguridad de Confianza";
   }

   render() {
      return (
         <React.Fragment>
            <Identity />
            <Outlet />
            <Footer />
         </React.Fragment>
      );
   }
}

export default RootScreen;
