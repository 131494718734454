import React from 'react';
import sisegLogo from '../../assets/images/siseg-logo-300x99.png';
import './siseg.corporation.scss';

class SisegCorporation extends React.Component {
   render() {
      return (
         <React.Fragment>
            <section className="corporate">
               <div className="container">
                  <div className="columns is-centered">
                     <div className="column is-5 has-text-centered py-6">
                        <h1 className='title'>CONOCE NUESTRO <span>CORPORATIVO</span></h1>
                     </div>
                  </div>
               </div>
               <div className="container">
                  <div className="columns is-centered is-flex-direction-column">
                     <div className="column has-text-centered">
                        <img src={sisegLogo} alt="" title='Sistemas de Seguridad Integral' />
                     </div>
                     <div className="column has-text-centered">
                        <a href="https://www.siseg-mexico.com" target={'_blank'} className='button'>Visitar Sitio</a>
                     </div>
                  </div>
               </div>
            </section>
         </React.Fragment>
      );
   }
}

export default SisegCorporation;