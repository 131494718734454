import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo2x.png'
import './Footer.scss';

class Footer extends React.Component {
   render() {
      return (
         <footer className="footer">
            <div className="getBudgetNow">
               <div className="container">
                  <div className="columns is-vcentered">
                     <div className="column is-8">
                        <p className="title is-4">Protege a tu patrimonio, protege a tus seres queridos.</p>
                     </div>
                     <div className="column is-4">
                        <Link to={"/contact"} className="button is-primary px-6 py-5">Obt&eacute;n una cotizaci&oacute;n</Link>
                     </div>
                  </div>
               </div>
            </div>

            <div className="footerContent">
               <div className="container">
                  <div className="columns">
                     <div className="column is-3">
                        <img src={logo} width="206" alt="Logo" />
                     </div>
                     <div className="column is-9 has-text-right">
                        <nav className='footerLinks'>
                           <Link to={"/services"} className="services">Servicios</Link>
                           <Link to={"/about-us"} className="aboutus">Acerca de Nosotros</Link>
                           <Link to={"/politica-de-privacidad"} className="aboutus">Politica de Privacidad</Link>
                           <Link to={"/contact"} className="contact">Contacto</Link>
                        </nav>
                        <p className="copyright">
                        SISEGUARD © Sistemas de Guardias de Seguridad { (new Date).getFullYear() }<br/>Todos los derechos reservados 
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      );
   }
}

export default Footer;