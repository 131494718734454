import React from 'react';
import logo from '../../assets/images/logo2x.png';
import shieldStar from '../../assets/images/shieldstar.svg';
import shield18 from '../../assets/images/shield18.svg';
import './AboutUs.scss';

class AboutUs extends React.Component {
   render() {
      return (
            <section className="aboutUs">
               <div className="container">
                  <div className="columns">
                     <div className="column is-5"></div>
                     <div className="column is-7 has-text-centered">
                        <img className='py-4' src={logo} width='288' alt="Logo" />
                        <p className="context pb-5">No solo nos interesamos en tener el mejor personal se seguridad, nos interesas tu, tu familia y la seguridad de tu patrimonio.</p>
                        <p className="context-item"><img src={shieldStar} alt="" /> Somos parte del a fundación de SISEG (Sistemas de Seguridad Integral), empresa que cuenta con más de 18 años de experiencia dentro de la Ciudad de Puebla.</p>
                        <p className="context-item"><img src={shield18} alt="" />Más de 20,000 familias y empresarios satisfechos con la seguridad brindada por nuestros servicios y principalmente nuestro persona.</p>
                     </div>
                  </div>
               </div>
            </section>
        );
   }
}

export default AboutUs;