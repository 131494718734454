import React from "react";
import ContactUs from "../../core/contact-us/contact-us";

class ContactUsScreen extends React.Component {
   render() {
      return (
         <ContactUs />
      );
   }
}

export default ContactUsScreen;