import React from "react";
import family from '../../assets/images/about-us-family.png';
import cctv from '../../assets/images/about-us-camera.png';
import './about-us.screen.scss';
import { Link } from "react-router-dom";

class AboutUsScreen extends React.Component {
   render(): React.ReactNode {
      return (
         <React.Fragment>
            <section className="hero is-medium about-us">
               <div className="hero-body">
                  <p className="hero-title">
                     Acerca <br />de Nosotros
                  </p>
               </div>
            </section>
            <section className="content">
               <div className="container">
                  <div className="columns is-multiline">
                     <div className="column is-4">
                        <h1 className="title">Nuestra misión es hacer tu vida más segura y tranquila.</h1>
                     </div>
                     <div className="column is-8">
                        <p className="text">SISEGUARD es un sistema de guardias de seguridad que ofrece un servicio integral y complementa la misión que nace a partir de la fundación de SISEG (Sistemas de Seguridad Integral), empresa que nace en el año de 2002 dentro de la ciudad de Puebla, en donde además de ofrecer servicios de tecnología y calidad otorgan seguridad y tranquilidad a los usuarios por medio de su capital humano.</p>
                     </div>
                     <div className="column is-4">
                        <img src={family} alt="SISEGuard Family" />
                     </div>
                     <div className="column is-8">
                        <img src={cctv} alt="SISEGuard Security" />
                     </div>
                     <div className="column is-4">
                        <p className="text">Somos una empresa poblana que nace de la necesidad de contar con elementos de seguridad capacitados para fungir como apoyo dentro de las empresas y los conjuntos habitacionales.</p>
                     </div>
                     <div className="column is-8">
                        <p className="text">Uno de los principales objetivos es salvaguardar la seguridad de los bienes inmuebles y la integridad física de las personas que buscan estos servicios. Es importante mencionar que al contar con una empresa hermana la intención de brindar servicios de apoyo de seguridad a través de SISEGUARD es crear un respaldo que contribuya a con la seguridad por medio de los más altos estándares que requieren las personas, para ello se contará con personal debidamente capacitado y apegado a los protocolos que dicta la normatividad dentro de esta materia.</p>
                     </div>
                  </div>
               </div>
            </section>
            <section id="servicesMosaic" className="check-services">
               <div className="container">
                  <h1 className="title">Conoce nuestros servicios</h1>
                  <p className="text">En SISEGUARD tenemos una variedad de servicios que solucionan tus necesidades de seguridad. Desde guardias comerciales hasta guardias de seguridad para residenciales y condominios con la mejor capacitación en el mercado.</p>
                  <Link to={'/services'} className="button is-transparent">Ver servicios</Link>
               </div>
            </section>
         </React.Fragment>
      );
   }
}

export default AboutUsScreen;