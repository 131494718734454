import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootScreen from './screens/root/root.screen';
import HomeScreen from './screens/home/home.screens';
import ServicesScreen from './screens/services/services.screen';
import AboutUsScreen from './screens/about-us/about-us.screen';
import ContactUsScreen from './screens/contact-us/contact-us.screen';
import PrivacyPolicyScreen from './screens/privacy-policy/privacy-policy.screen';

const router = createBrowserRouter([
   {
      path: '/',
      element: <RootScreen />,
      children: [
         {
            path: '/',
            element: <HomeScreen />
         },
         {
            path: '/services',
            element: <ServicesScreen />
         },
         {
            path: '/about-us',
            element: <AboutUsScreen />
         },
         {
            path: '/contact',
            element: <ContactUsScreen />
         },
         {
            path: '/politica-de-privacidad',
            element: <PrivacyPolicyScreen />
         }
      ]
   }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
