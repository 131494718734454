import React from "react";
import AboutUs from "../../core/about-us/AboutUs";
import Awareness from "../../core/awareness/Awareness";
import SisegCorporation from "../../core/corporation/siseg.corporation";
import Hero from "../../core/hero/Hero";
import KnowMore from "../../core/know-more/KnowMore";
import Process from "../../core/process/Process";
import Reviews from "../../core/reviews/Reviews";

class HomeScreen extends React.Component {
   render() {
      return (
         <React.Fragment>
            <Hero />
            <Awareness />
            <KnowMore />
            <Process />
            <AboutUs />
            <SisegCorporation />
         </React.Fragment>
      );
   }
}

export default HomeScreen;