import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-x2.png';
import './KnowMore.scss';

class KnowMore extends React.Component {
   render() {
      return (
         <section className="know-more">
            <div className="container">
               <div className="columns is-vcentered is-centered">
                  <div className="column is-8 has-text-centered">
                     <img className='mt-6 mb-5' src={logo} width='224' alt="Logo" />
                     <h1 className='title mt-5 mb-5'>¿Listo para adquirir el personal de seguridad mejor capacitado para apoyarte en la seguridad de tu empresa o conjunto habitacional?</h1>
                     <p className="content mt-4 mb-4 pt-4 pb-4">Nuestro personal se encuentra capacitado para poder responder rápidamente a diferentes situaciones que comprometan la seguridad de tus bienes o tu familia. Nuestro proceso de contratación es muy estricto.</p>
                     <Link to={"/services"} className="button is-medium is-outlined mt-5 mb-6">Descubre m&aacute;s de nuestro proceso</Link>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default KnowMore;