import React from "react";
import './services.screen.scss';

class ServicesScreen extends React.Component {
   render() {
      return (
         <React.Fragment>
            <div className="hero services">
               <div className="hero-body">
                  <h1 className="hero-title">SERVICIOS <br />SISEGUARD.</h1>
               </div>
            </div>
            <section className="content right-cta">
               <div className="container">
                  <div className="columns">
                     <div className="column is-half">
                        <h1 className="title">Guardias de Seguridad residenciales y para condominios.</h1>
                        <p className="text">Elementos de seguridad capacitados en el control de visitas, supervisión de estacionamientos en la prevención del delito en casa habitación y están en constante comunicación con las autoridades locales competentes en lo referente a seguridad.</p>
                        <ul>
                           <li>Control de acceso de visitas / proveedores</li>
                           <li>Revisión vehicular Rondas de vigilancia</li>
                           <li>Control de estacionamientos</li>
                           <li>Recordatorio de cumplimiento de reglamento</li>
                        </ul>
                     </div>
                     <div className="column is-half">
                        <div id="gsrc" className="picholder">
                           <div className="itemLabel">
                              <h1 className="theTitle">Ideal para:</h1>
                              <h1 className="theSubtitle">Condominios, privadas y fraccionamientos</h1>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="content left-cta">
               <div className="container">
                  <div className="columns">
                     <div className="column is-half">
                        <div id="gscc" className="picholder">
                           <div className="itemLabel">
                              <h1 className="theTitle">Ideal para:</h1>
                              <h1 className="theSubtitle">Oficinas, edificios y ejecutivos</h1>
                           </div>
                        </div>
                     </div>
                     <div className="column is-half">
                        <h1 className="title">Guardias de Seguridad Centro Comerciales.</h1>
                        <p className="text">Servicio especializado en el control de entrada y salida de mercancías, revisión de personal y resguardo de negocios con venta al público, donde el guardia además de realizar sus labores de vigilancia, se convierte en un instrumento de control para minimizar el riesgo de robo hormiga y como ente disuasivo contra el delincuente que proviene del exterior.</p>
                        <ul>
                           <li>Control de ingreso de mercancías </li>
                           <li>Rondas en puntos estratégicos</li>
                           <li>Vigilancia preventiva en pisos de venta (guardias infiltrados)</li>
                           <li>Revisión de salida de mercancía a contra tickets</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
            <section className="content right-cta">
               <div className="container">
                  <div className="columns">
                     <div className="column is-half">
                        <h1 className="title">Guardias de Seguridad para construcciones y obras.</h1>
                        <p className="text">Estos guardias están especializados en el control de acceso tanto de personal, de visitantes, de proveedores, así como vehicular. Tienen la capacitación adecuada de las actividades dentro de las obras.</p>
                        <ul>
                           <li>Control de acceso de visitas / proveedores</li>
                           <li>Revisión vehicular Rondas de vigilancia</li>
                           <li>Control de estacionamientos</li>
                           <li>Recordatorio de cumplimiento de reglamento</li>
                        </ul>
                     </div>
                     <div className="column is-half">
                        <div id="gsco" className="picholder">
                           <div className="itemLabel">
                              <h1 className="theTitle">Ideal para:</h1>
                              <h1 className="theSubtitle">Obras, terrenos, bodegas de materiales</h1>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="content left-cta">
               <div className="container">
                  <div className="columns">
                     <div className="column is-half">
                        <div id="gsei" className="picholder">
                           <div className="itemLabel">
                              <h1 className="theTitle">Ideal para:</h1>
                              <h1 className="theSubtitle">Fabricas, empresas y bodegas</h1>
                           </div>
                        </div>
                     </div>
                     <div className="column is-half">
                        <h1 className="title">Guardias de Seguridad empresariales e industriales</h1>
                        <p className="text">El servicio de seguridad empresarial e industrial funciona bajo las mismas características que el servicio corporativo, la diferencia radica en que el perfil del guardia es de nivel bachillerato y porta uniforme en lugar de traje.</p>
                        <ul>
                           <li>Control de acceso a visitantes / personal / proveedores</li>
                           <li>Control de entrada y salida de producto / equipo</li>
                           <li>Revisión vehicular</li>
                           <li>Rondas de vigilancia</li>
                           <li>Verificación de brigada de seguridad</li>
                           <li>Control de llamadas telefónicas</li>
                           <li>Verificación de cumplimiento en normas de seguridad</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
            <section className="content right-cta">
               <div className="container">
                  <div className="columns">
                     <div className="column is-half">
                        <h1 className="title">Guardias de seguridad para eventos.</h1>
                        <p className="text">Con base en los antecedentes y características del evento se evalúan riesgos proponiendo un plan integral de protección y así proporcionar el número de guardias necesarios para llevar a cabo operativo de seguridad, manteniendo una supervisión constante durante el tiempo que dure el evento o la custodia.</p>
                        <ul>
                           <li>Control de accesos (presencia física / monitoreo electrónico)</li>
                           <li>Rondas de vigilancia externas / internas</li>
                           <li>Registro de personal / visitantes</li>
                           <li>Control de entrada y salida de equipo y objetos</li>
                        </ul>
                     </div>
                     <div className="column is-half">
                        <div id="gse" className="picholder">
                           <div className="itemLabel smaller">
                              <h1 className="theTitle">Ideal para:</h1>
                              <h1 className="theSubtitle">Exposiciones, conferencias, fiestas, conciertos.</h1>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="content left-cta">
               <div className="container">
                  <div className="columns">
                     <div className="column is-half">
                        <div id="gsc" className="picholder">
                           <div className="itemLabel">
                              <h1 className="theTitle">Ideal para:</h1>
                              <h1 className="theSubtitle">Fabricas, empresas y bodegas.</h1>
                           </div>
                        </div>
                     </div>
                     <div className="column is-half">
                        <h1 className="title">Guardias de Seguridad Corporativos.</h1>
                        <p className="text">Cuentan con estudio de nivel superior, amplio criterio y se presentan uniformados con traje. Están capacitados de manera integral en servicios de protección y seguridad.</p>
                        <ul>
                           <li>Control de acceso (Presencia física / monitoreo electrónico)</li>
                           <li>Rondas de vigilancia externas / internas</li>
                           <li>Registro de personal / visitantes</li>
                           <li>Control de entrada y salida de equipo y objetos</li>
                           <li>Control de llamadas</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
         </React.Fragment>
      );
   }
}

export default ServicesScreen;