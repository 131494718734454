import React from "react";
import './privacy-policy.screen.scss';

class PrivacyPolicyScreen extends React.Component {
   render() {
      return (
         <React.Fragment>
            <div className="section container privacy-policy">
               <div className="columns">
                  <div className="column">
                     <h1 className="title">AVISO DE PRIVACIDAD</h1>
                     <p className="context has-text-justified">Los datos personales que sean recopilados por SISEGUARD© Sistemas de Guardias de Seguridad, serán incorporados, protegidos y tratados, con fundamento en las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares; sólo para brindar los productos y servicios solicitados y para informar de nuevos productos. Así mismo, SISEGUARD© Sistemas de Guardias de Seguridad se compromete a resguardar tu información personal con los más altos índices de seguridad legal, tecnológica y administrativa. Así como, a no vender, alquilar, compartir o divulgar tu información personal a terceros con fines ilícitos o contrarios a los de su titular. En caso de que desee rectificar este aviso puedes contactarnos en nuestra página www.siseguard.com SISEGUARD© Sistemas de Guardias de Seguridad, 39 Poniente No. 3302 Local D, Col. El Vergel, Puebla, Puebla C.P. 72400</p>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}

export default PrivacyPolicyScreen;