import React from 'react';
import logo from '../../assets/images/logo2x.png';
import callUs from '../../assets/images/callUs.svg';
import './Identity.scss';
import { NavLink, Link } from 'react-router-dom';

class Identity extends React.Component {
   render() {
      return (
         <section className="identity">
            <div className="container">
               <div className="columns is-vcentered">
                  <div className="column is-9-desktop">
                     <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                        <div className="navbar-brand">
                           <Link to={'/'} className="navbar-item">
                              <img src={logo} width="186" height="auto" alt='Siseguard Logo' />
                           </Link>

                           <a role="button" onClick={this.toggleMainMenu} className="navbar-burger navbar-end" aria-label="menu" aria-expanded="false" data-target="mainMenuItems">
                              <span aria-hidden="true"></span>
                              <span aria-hidden="true"></span>
                              <span aria-hidden="true"></span>
                           </a>
                        </div>

                        <div id="mainMenuItems" className="navbar-menu">
                           <div className="navbar-end">
                              <NavLink to={'/about-us'} className={this.setActiveMenuItem}>
                                 Acerca de nosotros
                              </NavLink>

                              <NavLink to={'/services'} className={this.setActiveMenuItem}>
                                 Servicios
                              </NavLink>
                           </div>
                        </div>
                     </nav>
                  </div>
                  <div id="callUsBox" className="column is-3 is-hidden-touch">
                     <div className='call-us'>
                        <a href="tel:2224051520" target={'_blank'} className="whatsApp">
                           <span className="title">Ll&aacute;manos ahora:</span>
                           <span className="subtitle">+52 222 405 1520</span>
                           <img src={callUs} height="50" width="auto" />
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }

   toggleMainMenu() {
      document.getElementById('mainMenuItems')?.classList.toggle('is-active');
   }

   setActiveMenuItem(state: any): string {
      return state.isActive ? 'navbar-item is-active' : 'navbar-item';
   }
}

export default Identity;
