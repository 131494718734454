import React from 'react';
import phone from '../../assets/images/phone.svg';
import people from '../../assets/images/people.svg';
import talking from '../../assets/images/talking.svg';
import './Process.scss';
import { Link } from 'react-router-dom';

class Process extends React.Component {
   render() {
      return (
         <section className='process'>
            <div className="container">
               <div className="columns is-centered">
                  <div className="column is-7 has-text-centered mt-6 mb-6">
                     <h1 className='title'>OBTEN CLARIDAD Y SEGURIDAD EN TAN SOLO <span>3 SIMPLES PASOS</span></h1>
                  </div>
               </div>
               <div className="columns is-centered steps">
                  <div className="column is-4 has-text-centered">
                     <img src={phone} className="processIcon the-phone" alt="" />
                     <h1 className="title is-5">Obtén una cotización a tu medida.</h1>
                     <p>Proporcione algunos detalles básicos, luego tendremos una breve llamada. No hay obligación de continuar.</p>
                  </div>
                  <div className="column is-4 has-text-centered">
                     <img src={people} className="processIcon the-people" alt="" />
                     <h1 className="title is-5">Reciben un plan detallado.</h1>
                     <p>Sí se siente cómodo para continuar, prepararemos su estrategia y la revisaremos con usted.</p>
                  </div>
                  <div className="column is-4 has-text-centered">
                     <img src={talking} className="processIcon the-talking" alt="" />
                     <h1 className="title is-5">Su hogar y oficina seguros.</h1>
                     <p>Nuestro personal capacitado estará en donde usted nos indique brindándole seguridad y confianza.</p>
                  </div>
               </div>
               <div className="colums getBudgetBox pb-6">
                  <div className="column has-text-centered">
                     <Link to={"/contact"} className="getBudgetBtn button p-5 is-large is-primary is-rounded is-hidden">&Oacute;bten una cotizaci&oacute;n</Link>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Process;