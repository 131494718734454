import React from "react";
import './Awareness.scss';
import stars from '../../assets/images/stars.svg';
import shield from '../../assets/images/exclamationshield.svg';

class Awareness extends React.Component {
   render() {
      return (
         <section className="awareness">
            <div className="container awareness-box">
               <div className="columns is-centered">
                  <div className="column is-7 has-text-centered">
                     <span className="stars"><img src={stars} alt="" /></span>
                     <p className="awareness-title">LA SEGURIDAD<br /> DE TU <span>FAMILIA Y TUS BIENES</span> PUEDE ESTAR EN RIESGO.</p>
                     <article className="notice-box">
                        <img src={shield} className="shield" alt="Shield" />
                        <p className="context">
                           En México cada mes se roban aproximadamente <span>629 casas</span>, lo que equivale a <span>21 viviendas por día</span> o de forma alarmante un hurto a casa habitación cada hora.
                        </p>
                     </article>
                     <div className="question">
                        <p>
                           ¿No se merece tu familia una vida más tranquila y segura? ¿Alguna vez te ha preocupado dejar tu casa solo al salir de vacaciones? ¿Ha estado buscando personal de seguridad en el que pueda confiar? La mayoría de la gente lucha con estas mismas preguntas. <span>No estás solo y estamos aquí para ayudarte.</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Awareness;