import React from 'react';
import './Hero.scss';

class Hero extends React.Component {
   render() {
      return (
         <section className="hero root">
            <div className="container">
               <div className="columns is-vcentered">
                  <div className="column is-5"></div>
                  <div className="column is-7">
                     <div className="hero-body">
                        <p className="hero-title">
                           TE AYUDAMOS A PROPORCIONAR LA MEJOR SEGURIDAD PARA TU EMPRESA Y HOGAR.
                        </p>
                        <p className='hero-text'>Somos un sistema de guardias de seguridad que ofrece un servicio integral y complementa la misión de otorgarte la seguridad y tranquilidad que merece tu y tu familia.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Hero;